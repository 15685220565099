.rs-list-item {
  background-color: #FFFFFFF5;
}

.rootSite {
  background-image: url("https://cnljs.com/images/pattern3.jpg");
  background-size: cover;
  display: flex;
  justify-content: center;
  width: 927px;
  height: 650px;
  margin: auto;
  overflow: auto;
}


#log-container { overflow: auto; height: 150px; }
#log {
  width: 100%;
  height: 300px;
}

.log-warn { color: orange }
.log-error { color: red }
.log-info { color: skyblue }
.log-log { color: silver }

.log-warn, .log-error { font-weight: bold; }
