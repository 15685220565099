.centerEverything {
  display: flex;
  flex-direction: column;

}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
