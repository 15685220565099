.test {
  color: red;
}

.main {
  display: flex;
  justify-content: center;
  align-self: left;
  width: 854px;
  height: 525px;
}

.wrapper {
  position: relative;
  width: 700px;
}

.options {
  position: absolute;
  top: 0;
  right: 0;
  width: 600px;
}

.playerListAndChat {
  height: 525px;
}

.game {
  height: 560px;
}

.mobile {
  display: flex;
  flex-direction: column;
  /*min-height: 100vh;/*100vh == height:100%, the element is stretched to the same height as the screen*/
  min-width: 100vw;
}
.mobileContent {
  flex: 1;
}
.mobileFooter {
  width: 100%;
  height: 50px;
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  display: flex;
}
.mobileNav {
  Flex:1;/* equals*/
  border: 1px solid #3498ff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3498ffAA;
  color: white;
}
